@import url('https://fonts.googleapis.com/css2?family=Homemade+Apple&display=swap');

.feeDoc {
  font-family: 'Arial', sans-serif;
  font-size: 11.5px;
  line-height: 1.2;
}
.feeDoc h3 {
  margin: 16px 0;
  font-size: 12.5px;
}
.feeDoc h4 {
  font-style: italic;
  font-size: 12.1px;
}
.feeDoc sup {
  font-size: 0.55rem;
}
.feeDoc .id-number {
  display: flex;
  justify-content: flex-end;
  font-size: 8.9px;
  margin-bottom: 6px;
}
.feeDoc ul li {
  margin: 9px 0;
}
.feeDoc header {
  margin-bottom: 23px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 13px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.feeDoc header img {
  width: 218px;
}
.feeDoc header .title-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 235px;
}
.feeDoc header h1 {
  font-size: 13px;
  margin: 0 0 13px;
  line-height: 1.25;
}
.feeDoc header h2 {
  font-size: 12px;
  font-weight: normal;
  margin: 0;
  line-height: 1.25;
}
.feeDoc .table-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
}
.feeDoc table {
  margin-bottom: 7px;
  border-collapse: collapse;
}
.feeDoc table td {
  padding: 2.25px 0;
}
.feeDoc .table-1 tr:not(:first-child) {
  border-top: 1px solid black;
}
.feeDoc .table-2 {
  margin-top: 22px;
}
.feeDoc .table-2 tr:nth-child(3),
.feeDoc .table-2 tr:nth-child(4),
.feeDoc .table-2 tr:nth-child(5),
.feeDoc .table-2 tr:nth-child(6),
.feeDoc .table-2 tr:nth-child(7) {
  border-top: 1px solid black;
}
.feeDoc .table-2 td:first-child {
  width: 120px;
}
.feeDoc .table-2 td:nth-child(2) {
  width: 120px;
}
.feeDoc .table-2 td:nth-child(3),
.feeDoc .table-2 td:nth-child(4) {
  text-align: center;
}
.feeDoc .date-wrapper {
  display: flex;
  width: fit-content;
  flex-direction: column;
  margin: 35px 0 20px;
}
.feeDoc .line-above {
  border-top: 1px solid black;
}

.feeDoc .writtenName {
  margin-left: 0.5rem;
  overflow-x: scroll;
  white-space: nowrap;
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;
}

.feeDoc .signature {
  font-family: 'Homemade Apple', cursive;
  position: absolute;
  left: 6.5rem;
  font-size: 0.9rem;
  overflow-x: scroll;
  white-space: nowrap;
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  top: -0.5rem;
}

/* Webkit based browser scrollbar */
.feeDoc .writtenName::-webkit-scrollbar,
.feeDoc .signature::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 4.8px;
}

.feeDoc .writtenName::-webkit-scrollbar-thumb,
.feeDoc .signature::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* Non webkit based browser scrollbar */
.feeDoc .signature,
.feeDoc .writtenName {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.5);
}
